body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

:root {
  
  /* opacity */
  --alpha-zero: 0;
  --alpha-one: 0.1;
  --alpha-two: 0.2;
  --alpha-three: 0.3;
  --alpha-four: 0.4;
  --alpha-five: 0.5;
  --alpha-six: 0.6;
  --alpha-seven: 0.7;
  --alpha-eight: 0.8;
  --alpha-nine: 0.9;

  /* colors */
  --rgb-white: 255, 255, 255;
  --rgb-theme: 64, 121, 140;
  --rgb-theme-light: 178,217,216,255;
  --rgb-black: 0, 0, 0;
  --rgb-dark-grey: 92, 92, 92;
  --rgb-light-grey: 161, 161, 161;
  --rgb-chambray: 84, 96, 120;
  --rgb-black-pearl: 23, 36, 38;
  --rgb-nero-black: 20, 20, 20;
  --rgb-nurse-grey: 209, 210, 205;
  --rgb-wood-brown: 159, 126, 105;
  --rgb-smoke-white: 242, 242, 242;
  --rgb-ivory: 252, 252, 250;
}

.pointer{
  cursor: pointer;
}

ul {
  list-style-type: none;
  padding: 0;
}

h1,h2,h3,h4,h5,h6, p{
  margin: 0;
}

.indentation{
  max-width: 80vw;
  margin: 0 auto;
}

@media(max-width: 1200px){

  .indentation{
    max-width: 94vw;
  }
}

@media(max-width: 809px){

  .indentation{
    max-width: 95vw;
  }
}